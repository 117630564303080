import React from 'react';
import cmsUtils from '../../utils/cmsUtils';
import utils from '../../../utils';
import { SiteLink } from '../../../components';

export function BlogPosts(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};

  console.log('item', item);

  const posts = getPages(cmsUtils.payload(item, 'BlogPosts'));
  const numOfTileLines = parseInt(cmsUtils.payload(item, 'NumOfTileLines', '2'));
  //console.log(numOfPost, parseInt(numOfPost));
  console.log("posts", posts);
  const bp = utils.ui.getCurrentBreakpoint();
  const tilesPerLine = {xs:1, sm:1, md:2, lg:2, xl:3, xxl:4}
  const numOfPost = numOfTileLines * (tilesPerLine[bp] || 3);
  const step = numOfPost;
  console.log(bp, numOfTileLines, tilesPerLine, numOfPost)

  const [tilesToShow, setTilesToShow] = React.useState(numOfPost);

  const displayingPosts = posts.length > tilesToShow ? posts.slice(0, tilesToShow) : posts;
  const showMore = posts.length > displayingPosts.length;

  const pageTileElts = displayingPosts.map((page, index) =>
    <Tile page={page} key={index} disableLink={cmsOption.isCmsEdit}></Tile>
  );

  const cssClass = utils.classNames('cms_item', 'blogTiles tiles', item.cssClass || item.anchorName || '');

  return (
    <>
      <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
        {pageTileElts}
      </div>
      {showMore && (<div className="blogTiles__loadMore">
        <span className="thinBtn" onClick={(e) => { setTilesToShow(tilesToShow + step) }}>LOAD MORE</span></div>)}
    </>
  );
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}

function Tile(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const disableLink = props.disableLink;
  const postDate = utils.date.formatDDMMYYYY(page.postDate);

  return (
    <div className="blogTile">
      <SiteLink className="blogTile__link" to={disableLink ? '' : page.pageUrl}>
        <div className="blogTile__bg" style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}></div>
        <div className="blogTile__content">
          <div className="blogTile__contentInner">
            <div className="blogTile__title">
              {page.pageTitle}
            </div>
            {/* <div className="blogTile__desc">
              {page.tileDesc || page.description}
            </div> */}
            <div>
              {postDate} Test
            </div>
            <div className="blogTile__readMore thinBtn">READ MORE</div>
          </div>
        </div>
      </SiteLink>
    </div>
  )
}