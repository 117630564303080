import React from 'react';
import cmsUtils from '../../utils/cmsUtils';
import  utils from '../../../utils';
import { SiteLink } from '../../../components';

export function SingleBanner(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const alignV = cmsUtils.payload(item, 'AlignV') || '50%';
  const cssClass = utils.classNames('cms_item', 'single-banner', 'banner-item',item.cssClass || item.anchorName || '');
  const bgUrl = utils.site.resourcePath(cmsUtils.payload(item, 'ImageUrl'));
  const captionTitle = cmsUtils.payload(item, 'CaptionTitle2') || cmsUtils.payload(item, 'CaptionTitle');
  const linkUrl = cmsUtils.payload(item, 'LinkUrl2') || cmsUtils.payload(item, 'LinkUrl');
  const titlePanel = captionTitle ? (
    linkUrl ? (<SiteLink to={linkUrl} className="banner-item__link">
      <div className="banner-item__title" dangerouslySetInnerHTML={{ __html: captionTitle }}></div>
    </SiteLink>) : (<div className="banner-item__title" dangerouslySetInnerHTML={{ __html: captionTitle }}></div>)
  ) : null;

  return (
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}
      style={{ backgroundImage: utils.css.bgUrlStyle(bgUrl), backgroundPositionY:alignV }}>
      {titlePanel && <div className="banner_item__text">{titlePanel}</div>}
      <div className="banner_content">
        {/* <p className="banner_title1">M<span className="banner_title1_span">EDICAL</span> & D<span className="banner_title1_span">ENTAL</span></p>
        <p className="banner_title2">GLENGOWIRE</p>
        <p className="banner_subTitle">Experienced General Practitioners. <br /> Quality Medical & Dental Care.</p>
        <button className="banner_btn3">CONTACT US</button> */}
      </div>
    </div>
  );
}
