import React from 'react';
import { useRouter } from '../../components';
import { SiteLink } from '../../components';

export function BottomDock(props) {
  const links = [
    { url: '/medical', title: 'MEDICAL', icon: 'MEDICAL-GREEN.png', iconHover: 'white/MEDICAL-WHITE.png' },
    { url: '/dental', title: 'DENTAL', icon: 'DENTAL-GREEN.png', iconHover: 'white/DENTAL-WHITE.png' },
    { url: '/book-medical', title: 'APPOINTMENT', icon: 'APPOINTMENT-GREEN.png', iconHover: 'green/APPOINTMENT-GREEN.png' },
    { url: '/about', title: 'ABOUT', icon: 'ABOUT-GREEN.png', iconHover: 'white/ABOUT-WHITE.png' },
    { url: '/contact', title: 'CONTACT', icon: 'CONTACT-GREEN.png', iconHover: 'white/CONTACT-WHITE.png' }
  ];

  const { pathname } = useRouter();

  const isCurrent = (link) => {
    return pathname && pathname.toLowerCase() === link.toLowerCase();
  }

  const linkElts = links.map((link, index) =>
    <li key={index} className={`bottomMenu__li bottomMenu__li--${index} ${isCurrent(link.url) ? 'selected' : ''}`}>
      <SiteLink to={link.url} className={`bottomMenu__link bottomMenu__link--${index}`}>
        <div className="icon">
          <img className="normal" src={`/assets/navicons/green/${link.icon}`} alt={link.title} />
          <img className="selected" src={`/assets/navicons/${link.iconHover}`} alt={link.title} />
        </div>
        <div>{link.title}</div>
      </SiteLink>
    </li>
  );

  return (
    <>
      <nav className="bottomMenu">
        <ul className="bottomMenu__links">
          {linkElts}
        </ul>
      </nav>
    </>
  );
}
