//import React from 'react';
import cmsUtils from '../../../cms/utils/cmsUtils';
import utils from '../../../utils';
import { SiteLink } from '../../../components';

/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './style';

export function PageTilesFourth(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const pages = getPages(cmsUtils.payload(item, 'PageObjects'))
  const pageTileElts = pages.map((page, index) =>
    <Tile page={page} key={index} disableLink={cmsOption.isCmsEdit} idx = {index}></Tile>
  );
  const cssClass = utils.classNames('cms_item', 'pageTiles__fourth', item.cssClass || item.anchorName || '');
  return (
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}
     css = {style.pageTiles}>
      {pageTileElts}
    </div>
  );
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}

function Tile(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const disableLink = props.disableLink;
  const sliderImgs = (page.bottomImageUrl && page.bottomImageUrl !== '') ? page.bottomImageUrl.split("|") : [];
  if(imageUrl && imageUrl !== ''){
    sliderImgs.unshift(imageUrl);
  }

  return (
    <div css = {style.pageTile__fourth} className="pageTile__fourth">
      <SiteLink className="pageTile__fourth__content" css = {style.pageTile__fourth__content} to={disableLink ? '' : page.pageUrl}>
        <div className='pageTile__fourth__wrapper'>
          <div className='pageTile__fourth__content__right'>
            <div className='pageTile__fourth__content__right__logo'>
              <img className="pageTile__fourth__content__right__img" src="/assets/tileicons/glengowrie-dental-logo-vertical.png" alt="Glengowrie Medical Center" />
            </div>
            <div className="pageTile__fourth__title">
              {page.pageTitle} 
            </div>
            <div className="pageTile__fourth__desc">
                {page.tileDesc || page.description}
              </div>
            <div className="pageTile__fourth__button">
              <button className='btn pageTile_fourth_btn'>
                KNOW MORE
              </button>
            </div>
          </div>
          <div>
            <div className="pageTile__fourth__bg" style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}></div>
          </div>
        </div>
      </SiteLink>
    </div>
  )
}