import { css } from '@emotion/core'
import { mq } from '../../../cssInJs'

export default {
    pageTiles : css({
         display: 'flex',
         justifyContent: 'flex-start',
         flexWrap: 'wrap',
         margin: '2rem -10px',
         overflow:'hidden',
         maxWidth : '1140px',
         marginLeft : 'auto',
         marginRight : 'auto'
    }),
    pageTile__tile : css(mq({
         flex: '0 0 100%',
         maxWidth: '100%',
         padding: '10px 0',
         zIndex:1,
         '&:nth-of-type(even)' : {
               '.pageTile__small2__bg' : mq({
                    order: ['0',null,'2'],
               }),
               '.pageTile__small2__content__details' : mq({
                    order: '1'
               })
         }
    })),

    tile__content : css({
         '&:hover' : {
            textDecoration: 'none',
            '& .pageTile__small2__content__details' : {
               backgroundColor: 'rgb(39, 127, 138)',
               '.pageTile__small2__title' : {
                    color : 'white'
               },
               '.pageTile__small2__desc' : {
                    color : 'white'
               },
               '.pageTile__small2__button' : {
                    backgroundColor : 'white',
                    color : 'rgb(39, 127, 138)'
               }
            }
         }
    }),

    tile__flip__wrapper : css({
          zIndex:'-1', 
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          height: '100%',
          cursor: 'pointer',
          position: 'relative',
          flexWrap: 'wrap'
    }),

    tile__bg : css(mq({
         height: '0',
         paddingTop: ['50%','35%','30%'],
         backgroundPosition: '50%',
         backgroundRepeat: 'no-repeat',
         backgroundSize: 'cover',
         flex: '1 1 300px'
    })),

    tile__details : css({
         overflow: 'hidden',
         flexGrow: '1',
         textAlign: 'center',
         width: '100%',
         bottom: '0',
         backgroundColor: '#eee',
         flex: '1 1 300px',
         transition: 'all .2s ease-in-out',
         color: 'black',
         position:'relative',
         '& .pageTile__small2__container' : mq({
            position: ['static,',null,'absolute'],
            top: '50%',
            left:'50%',
            transform: ['none',null,'translate(-50%, -50%)'],
            width:['100%',null,'90%'],
            padding:['1rem',null,'0px'],
            '& .pageTile__small2__title' : {
                fontSize: '1.2rem',
                fontWeight: '700',
                lineHeight: '1',
                margin: '0',
                paddingTop: '.5rem',
                paddingBottom: '.5rem',
                color:'rgb(39, 127, 138)'
            },
            '& .pageTile__small2__desc' : {
                lineHeight: '1.3',
                fontSize: '0.75rem',
                minHeight: '70px',
                color:'inherit'
             },
             '& .pageTile__small2__button' : {
               display : 'inline-block',
               margin : 'auto',
               padding : '.6rem 2.25rem',
               fontSize: '0.75rem',
               color : 'white',
               backgroundColor : 'rgb(39, 127, 138)',
               borderRadius: '5px',
               marginTop: '1rem',
               fontWeight : '700'
             }
         })
    })
}