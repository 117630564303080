import { mq,variables } from '../../../cssInJs'

export default {
    pageTiles__big: mq({
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        marginTop: '40px',
        marginBottom: '40px',
        // paddingLeft: '525px'
    }),
    pageTile__big__wrapper: ({
        '&.green': {
            borderBottomColor: variables.greenColor,
            '&:hover, &:focus, &.selected': {
                backgroundColor: variables.greenColor,
                borderBottomColor: variables.greenColor,
            },
        },
        '&.tiffanyGreen': {
            borderBottomColor: variables.tiffanyGreenColor,           
            '&:hover, &:focus, &.selected': {
                backgroundColor: variables.tiffanyGreenColor,
                borderBottomColor: variables.tiffanyGreenColor
            },
        },
        '&.waterGreen': {
            borderBottomColor: variables.waterGreenColor,
            '&:hover, &:focus, &.selected': {
                backgroundColor: variables.waterGreenColor,
                borderBottomColor: variables.waterGreenColor
            },
        }
    }),
    pageTile__big__title: ({
        '&.green': {
            color: variables.greenColor
        },
        '&.tiffanyGreen': {
            color: variables.tiffanyGreenColor
        },
        '&.waterGreen': {
            color: variables.waterGreenColor
        }
    }),
    pageTile_btn: ({
        '&.green': {
            color: variables.greenColor
        },
        '&.tiffanyGreen': {
            color: variables.tiffanyGreenColor
        },
        '&.waterGreen': {
            color: variables.waterGreenColor
        }
    })
}