/** @jsx jsx */
import { jsx } from '@emotion/core'
// import { useEffect } from 'react';
import utils from '../../utils';
import cmsUtils from '../../cms/utils/cmsUtils';

export function ImageBox(props){
    const item = props.item;
    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames('cms_item', 'imageBox', item.cssClass || item.anchorName || '');
    const _images = cmsUtils.payload(item, 'ImageUrl') ? cmsUtils.payload(item, 'ImageUrl').split("|") : [];
    const _thumbImages = [];
    if(_images.length > 0){
        _images.forEach(el => {
            let _texts = el.split('/');
            let _text = el.replace(_texts[_texts.length-1], "@thumb/" + _texts[_texts.length-1]);
            _thumbImages.push(_text);
        });
    }
    const imgUrl = _images;
    return(
        <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId}>
            <div className='imageBoxBg'>
                {
                    _thumbImages.map((item, index) =>
                     <div className="thumbnail imageBoxStyle translate" key={index}>
                         <img src={imgUrl} className="imageInside" data-aos="fade-right" alt=''/>
                     </div>
                    )
                }
            </div>
        </div>
    );   
}