import { css } from '@emotion/core';
import { mq } from '../../cssInJs';
export default {
  button: css(mq({
    position: 'absolute',
    left: '1rem',
    lineHeight: '1.6rem',
    color: '#166c75',
    fontSize: ['16px', null, '31px'],
    textDecoration: 'none'
  })),
}