import React from 'react';
import { Item } from '../../items/item';
import cmsUtils from '../../utils/cmsUtils';
import utils from '../../../utils';
import Slider from "react-slick";

export function BannerPanel(props) {
  const item = props.item;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};
  //console.log('item', item, subItems);
  const heightSet = cmsUtils.payload(item, 'HeightSet') || 'standard';
  const sliderContainer = React.useRef(null);
  const slides = subItems.map((subItem, index) =>
    <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
  );

  var settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    autoplaySpeed: 5000,
    fade: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => {

      sliderContainer.current.classList.remove('finish__blind');
      
      const _current = utils.site.resourcePath(cmsUtils.payload(subItems.at(current), 'ImageUrl'));
      const _next = utils.site.resourcePath(cmsUtils.payload(subItems.at(next), 'ImageUrl'));

      const _width = window.innerWidth / 10;
      const _transformOriginWidth = (_width / 2);
      //const _transformOriginWidth = 10;
      let bannerBlindTransition_1 = sliderContainer.current.querySelectorAll(`.bannerBlindTransition__first`);
      let bannerBlindTransition_2 = sliderContainer.current.querySelectorAll(`.bannerBlindTransition__second`);

      let _count = 1;
      bannerBlindTransition_1.forEach(el => {
        const _widthItem = _width * _count;
        el.style.backgroundImage = `url('${_current}')`;
        el.style.clip = `rect(0px, ${_widthItem}px, 840px, ${_widthItem - _width}px)`;
        el.style.transformOrigin = `${_widthItem - _transformOriginWidth}px 0px`;
        // if(_count !== 1){
        //   el.style.transitionDelay = `${_widthItem - 100}ms`;
        // }
        _count ++;
      });

      _count = 1;
      bannerBlindTransition_2.forEach(el => {
        const _widthItem = _width * _count;
        el.style.backgroundImage = `url('${_next}')`;
        el.style.clip = `rect(0px, ${_widthItem}px, 840px, ${_widthItem - _width}px)`;
        el.style.transformOrigin = `${_widthItem - _transformOriginWidth}px 0px`;
        if(_count === 10){
          //el.style.transitionDelay = `${_widthItem - 100}ms`;
          el.style.transformOrigin = `${_widthItem - _transformOriginWidth - 9}px 0px`;
        }
        _count ++;
      });

      sliderContainer.current.classList.add('prepare__blind');
    },
    afterChange : (index) => {
      setTimeout(() => {
        sliderContainer.current.classList.add('finish__blind');
        sliderContainer.current.classList.remove('prepare__blind');
      }, 700);
    }
  };

  const cssClass = utils.classNames('cms_item', 'bannerPanel', `bannerWrapperSize--${heightSet}`, item.cssClass || item.anchorName || '');

  return (
    <div ref={sliderContainer} className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
      
      <Slider {...settings} >
        {slides}
      </Slider>
      
      <div className='banner__blind__transition__container'>
        <div className='banner__blind__transition__inner'>
          
          <div className='bannerBlindTransition bannerBlindTransition__first i__1'></div>
          <div className='bannerBlindTransition bannerBlindTransition__first i__2'></div>
          <div className='bannerBlindTransition bannerBlindTransition__first i__3'></div>
          <div className='bannerBlindTransition bannerBlindTransition__first i__4'></div>
          <div className='bannerBlindTransition bannerBlindTransition__first i__5'></div>
          <div className='bannerBlindTransition bannerBlindTransition__first i__6'></div>
          <div className='bannerBlindTransition bannerBlindTransition__first i__7'></div>
          <div className='bannerBlindTransition bannerBlindTransition__first i__8'></div>
          <div className='bannerBlindTransition bannerBlindTransition__first i__9'></div>
          <div className='bannerBlindTransition bannerBlindTransition__first i__10'></div>
          
          <div className='bannerBlindTransition bannerBlindTransition__second i__11'></div>
          <div className='bannerBlindTransition bannerBlindTransition__second i__12'></div>
          <div className='bannerBlindTransition bannerBlindTransition__second i__13'></div>
          <div className='bannerBlindTransition bannerBlindTransition__second i__14'></div>
          <div className='bannerBlindTransition bannerBlindTransition__second i__15'></div>
          <div className='bannerBlindTransition bannerBlindTransition__second i__16'></div>
          <div className='bannerBlindTransition bannerBlindTransition__second i__17'></div>
          <div className='bannerBlindTransition bannerBlindTransition__second i__18'></div>
          <div className='bannerBlindTransition bannerBlindTransition__second i__19'></div>
          <div className='bannerBlindTransition bannerBlindTransition__second i__20'></div>

        </div>
      </div>

    </div>
  );
}