/** @jsx jsx */
import { jsx } from "@emotion/core";

import { useState, Fragment } from "react";
import { SiteLink } from "../../components";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { NavBar } from "./navBar";
import { BackButton } from "../backButton/backButton";
import { Hamburger } from "../hamburger/hamburger";
import style from "./topHeaderStyle";

export function TopHeader(props) {
  const [menuOpened, setMenuOpened] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (currPos.y > 90) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    },
    [],
    null,
    true
  );

  function toggleMenu(e) {
    setMenuOpened(!menuOpened);
  }

  function onCloseMenu() {
    setMenuOpened(false);
  }
  function backTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  return (
    <Fragment>
      <header
        css={[style.top, scrolled && style.topScrolled]}
        className='topHeader'
        id='top'
      >
        <BackButton />
        <SiteLink
          to='/'
          css={[style.logo, scrolled && style.logoScrolled]}
          className='medical_logo'
          onClick={backTop}
        >
          <img
            css={[style.logoImg, scrolled && style.logoImgScolled]}
            src='/assets/logos/logo.png'
            className={scrolled ? "medical_logo_scrolled" : ""}
            alt='logo'
          />
        </SiteLink>
        <SiteLink
          to='/dental'
          css={[style.logo, scrolled && style.logoScrolled]}
          className='dental_logo'
          onClick={backTop}
        >
          <img
            css={[style.logoImg, scrolled && style.logoImgScolled]}
            src='/assets/logos/dental_logo.png'
            alt='logo'
          />
        </SiteLink>
        <div css={[style.navBox]} className='topHeaderNavBox'>
          <Hamburger
            opened={menuOpened}
            toggleMenu={toggleMenu}
            scrolled={scrolled}
          />
          <NavBar open={menuOpened} onCloseMenu={onCloseMenu}></NavBar>
        </div>
      </header>
    </Fragment>
  );
}
