//import React from 'react';
import cmsUtils from '../../../cms/utils/cmsUtils';
import utils from '../../../utils';
import { SiteLink } from '../../../components';
/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './style';
// import { useEffect } from 'react';

export function PageTilesBsyc(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const pages = getPages(cmsUtils.payload(item, 'PageObjects'))
  const pageTileElts = pages.map((page, index) =>
    <Tile page={page} key={index} disableLink={cmsOption.isCmsEdit} idx = {index}></Tile>
  );
  const cssClass = utils.classNames('cms_item', 'pageTiles__big__css', item.cssClass || item.anchorName || '');
  return (
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}
     css = {style.pageTiles__big}>
      {pageTileElts}
    </div>
  );
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}

function Tile(props) {
  const page = props.page;
  const disableLink = props.disableLink;
  const colorTheme = page.colorTheme;
  const img_normal = page.tileIcon;
  const img_selected = page.hoverIcon;
  
  return (
    <div className="pageTile__big">
      <SiteLink className="pageTile__big__content" to={disableLink ? '' : page.pageUrl}>
        <div className={`pageTile__big__wrapper ${colorTheme}`} css={style.pageTile__big__wrapper}>
          <div className="tile_icons">
            <img className="normal" src={`/assets/tileicons/green/${img_normal}`} alt=''/>
            <img className="selected" src={`/assets/tileicons/white/${img_selected}`} alt=''/>
          </div>
          <div css={style.tile__details} className="pageTile__bsyc__content__details">
            <div className="pageTile__bsyc__container">
              <div className={`pageTile__big__title  ${colorTheme}`}>
                {page.pageTitle}
              </div>
              <div className="pageTile__bsyc__desc">
                {page.tileDesc || page.description}
              </div>
              <div className="pageTile__big__button">
                <button className={`btn pageTile_btn ${colorTheme}`}>
                  READ MORE &nbsp; &gt;
                </button>
              </div>
            </div>
          </div>
        </div>        
      </SiteLink>
    </div>
  )
}